<template>
  <div class="height1">
    <bread></bread>
    <!--    内容    -->
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top">
          <div class="iq-search-bar">
            <el-form :model="searchForm" :inline="true">
              <el-form-item>
                <el-date-picker v-model="searchForm.datepicker" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions0" size="small" style="margin-right: 10px" @change="Search"> </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="输入关键词搜索..." @keyup.enter.native="SearchInputKeydown" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="Search()">搜索</el-button>
              </el-form-item>
              <el-form-item>
                <download-excel v-if="tableData.length > 0" :data="tableData" :fields="json_fields" worksheet="My Worksheet" :header="title" :name="`${title}.xls`" class="excel">
                  <el-button type="warning" size="mini" class="el-icon-download">导出当前页数据</el-button>
                </download-excel>
              </el-form-item>
              <el-form-item>
                <download-excel v-if="DataCount" :fields="json_fields" :fetch="fetchData" worksheet="My Worksheet" :header="title" :name="`${title}_All.xls`" class="excel">
                  <el-button type="danger" size="mini" class="el-icon-download">导出{{ DataCount }}条数据</el-button>
                </download-excel>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <el-table :data="tableData" stripe height="calc(100% - 90px)" style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }">
          <el-table-column prop="learnName" label="员工">
            <template slot-scope="data">
              <p>{{ data.row.learnName }}</p>
              <p>{{ data.row.phone }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="organizationName" label="所属机构" align="center"> </el-table-column>
          <el-table-column prop="departmentName" label="所在部门" align="center"> </el-table-column>
          <el-table-column prop="stayTime" label="浏览时长" align="center">
            <template slot-scope="data">{{ FormatSecond(data.row.stayTime) }}</template>
          </el-table-column>
          <el-table-column prop="name" label="最后浏览时间" align="center">
            <template slot-scope="scope"> {{ scope.row.lastTime | timefilters }} </template>
          </el-table-column>
          <el-table-column label="操作" width="100" align="center">
            <template slot-scope="scope">
              <span class="primary optionBtn" @click="ToDetail(scope.row.userId)">详情 </span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex" :total="DataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
      </div>
    </div>
    <!--    详情    -->
    <el-dialog title="时间统计详情" :visible.sync="dialogTableVisible" :before-close="handleClose">
      <el-table :data="DetailData" height="500" :header-cell-style="{ background: '#F5F7FC' }">
        <el-table-column prop="learnName" label="内容"></el-table-column>
        <el-table-column prop="type" label="分类">
          <template slot-scope="data">
            <p v-if="data.row.type == 'disease'">疾病</p>
            <p v-if="data.row.type == 'drug'">药品</p>
            <p v-if="data.row.type == 'material'">中药材</p>
            <p v-if="data.row.type == 'herbal'">中药方剂</p>
            <p v-if="data.row.type == 'food'">食疗药膳</p>
            <p v-if="data.row.type == 'acupoint'">穴位</p>
            <p v-if="data.row.type == 'meridian'">经络</p>
            <p v-if="data.row.type == 'video'">视频</p>
          </template>
        </el-table-column>
        <el-table-column prop="stayTime" label="浏览时长">
          <template slot-scope="data">{{ data.row.stayTime }}秒</template>
          <!-- <template slot-scope="data">{{ FormatSecond(data.row.stayTime) }}</template> -->
        </el-table-column>
        <el-table-column prop="quitTime" label="最后浏览时间">
          <template slot-scope="data">{{ data.row.quitTime | timefilters }}</template>
        </el-table-column>
      </el-table>
      <el-pagination @current-change="ChangeDetailPage" :page-size="PageSize" :current-page="PageIndex2" :total="DetailTotal" layout="total, prev, pager, next, jumper"></el-pagination>
    </el-dialog>
  </div>
</template>

<script>
import bread from '../../../components/common/breadcrumb'
import { STATS } from '../../../components/HospitalDomain/STATS'
import { StrToShotDate, FormatSecond } from '../../../components/common/Date'
export default {
  name: 'STATSStaff',
  components: {
    bread,
  },
  data() {
    var stats = new STATS(this.TokenClient, this.Services.Healthy)
    return {
      STATSDomain: stats,
      tableData: [],
      searchForm: {
        statrTime: '',
        endTime: '',
        keyWord: '',
        datepicker: '',
      },
      title: '员工时长统计数据',
      DataCount: 0,
      dialogTableVisible: false,

      DetailData: [],
      DetailTotal: 0,
      FormatSecond: FormatSecond,
      PageIndex: 1,
      PageIndex2: 1,
      PageTotal: 1,
      PageSize: 15,
      DataTotal: 0,
      userId: 0,
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
      },
      json_fields: {
        员工: 'learnName',
        员工手机号: 'phone',
        所属机构: 'organizationName',
        所在部门: 'departmentName',
        // "浏览时长": 'stayTime',
        浏览时长: {
          filed: 'stayTime',
          callback: (stayVal) => {
            var stayVal = stayVal.stayTime
            return FormatSecond(stayVal)
          },
        },
        // "最后浏览时间":'lastTime',
        最后浏览时间: {
          filed: 'lastTime',
          callback: (val) => {
            var val = val.lastTime
            return this.$fliters.timefilters(val)
          },
        },
      },
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    handleClose(done) {
      this.PageIndex2 = 1
      this.dialogTableVisible = false
    },
    ChangeDetailPage(pageIndex) {
      this.PageIndex2 = pageIndex
      this.ToDetail(this.userId)
    },
    ToDetail(userId) {
      this.userId = userId
      this.dialogTableVisible = true
      var _this = this
      _this.STATSDomain.StatisticsTime(
        userId,
        _this.PageIndex2,
        function (data) {
          _this.DetailData = data.data.results
          _this.PageIndex2 = data.data.pageIndex
          _this.PageTotal = data.data.pageCount
          _this.PageSize = data.data.pageSize
          _this.DetailTotal = data.data.dataTotal
        },
        function (err) {
          console.log(err)
        }
      )
    },
    SearchInputKeydown(event) {
      if (event.keyCode == 13) {
      }
    },
    Search(event) {
      this.PageIndex = 1
      this.getList()
    },
    ChangePage(pageIndex) {
      this.PageIndex = pageIndex
      this.getList()
    },
    getList() {
      var _this = this
      var item = _this.searchForm
      if (_this.searchForm.datepicker) {
        item.startTime = _this.searchForm.datepicker[0] + ' 00:00:00'
        item.endTime = _this.searchForm.datepicker[1] + ' 23:59:59'
      } else {
        item.startTime = ''
        item.endTime = ''
      }
      _this.STATSDomain.AllUserTime(
        item.startTime,
        item.endTime,
        item.keyWord,
        _this.PageIndex,
        function (data) {
          _this.tableData = data.data.results
          _this.PageIndex = data.data.pageIndex
          _this.PageSize = data.data.pageSize
          _this.PageTotal = data.data.pageCount
          _this.DataTotal = data.data.dataTotal
          _this.DataCount = data.data.dataTotal
        },
        function (error) {
          console.log(error)
        }
      )
    },
    async fetchData() {
      var _this = this
      return await _this.getAllData()
    },
    getAllData() {
      var _this = this
      return new Promise((resolve, reject) => {
        _this.STATSDomain.AllUserTime(
          _this.searchForm.startTime,
          _this.searchForm.endTime,
          _this.searchForm.keyWord,
          _this.searchForm.PageIndex,
          function (data) {
            resolve(data.data.results)
          },
          function (err) {
            resolve('')
            console.log(err)
          }
        )
      })
    },
  },
}
</script>

<style scoped></style>
